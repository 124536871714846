import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';

import {FooterComponent} from './components/footer/footer.component';
import {ValidationDialogComponent} from './components/dialog/validation-dialog/validation-dialog.component';
import {LoggerConfig} from './services/logger/logger.config';
import {environment} from '../../../environments/environment';
import {MaterialModule} from './material/material.module';
import {AvatarModule} from '../modules/avatar/avatar.module';
import {PlayerHeaderComponent} from './components/player-header/player-header.component';
import {LeaderboardComponent} from './components/leaderboard/leaderboard.component';
import {PlayerWidgetComponent} from './components/leaderboard/player-widget/player-widget.component';
import {PlayerSelectorComponent} from './components/player-selector/player-selector.component';
import {parse as ParseLogLevel} from './services/logger/loglevel';
import {LoadingComponent} from './components/loading/loading.component';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {LeaderboardServiceComponent} from './components/leaderboard-service/leaderboard-service.component';
import {ServiceWidgetComponent} from './components/leaderboard-service/service-widget/service-widget.component';
import {FrogEventAuthInterceptor} from '@frogconnexion/core-common/angular';
import {SpinnerComponent} from './components/spinner/spinner.component';

@NgModule({
  declarations: [
    FooterComponent,
    ValidationDialogComponent,
    PlayerHeaderComponent,
    LeaderboardComponent,
    LeaderboardServiceComponent,
    ServiceWidgetComponent,
    PlayerWidgetComponent,
    PlayerSelectorComponent,
    SpinnerComponent,
    LoadingComponent,
  ],
  exports: [
    PlayerHeaderComponent,
    PlayerSelectorComponent,
    LeaderboardComponent,
    LeaderboardServiceComponent,
    FooterComponent,
    MaterialModule,
    ValidationDialogComponent,
    LoadingComponent,
    SpinnerComponent,
  ],
  imports: [
    CommonModule,
    AvatarModule,
    MaterialModule,
    RouterModule,
    HttpClientModule,
    ScrollingModule,
  ],
  providers: [
    {provide: LoggerConfig, useValue: ParseLogLevel(environment.loggerConfig)},
    {provide: HTTP_INTERCEPTORS, useClass: FrogEventAuthInterceptor, multi: true}
  ]
})
export class CoreModule {
}
