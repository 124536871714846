import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AvatarSelectorComponent} from './avatar-selector/avatar-selector.component';
import {AvatarService} from './service/avatar.service';
import {MaterialModule} from "../../core/material/material.module";

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
  ],
  declarations: [
    AvatarSelectorComponent
  ],
  exports: [
    AvatarSelectorComponent,
  ],
  providers: [
    AvatarService
  ]
})
export class AvatarModule {}
