import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

const routes: Routes = [
  {
    path: 'org/:organization/lobby',
    loadChildren: () => import('./lobby.module').then(m => m.LobbyModule)
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)]
})
export class LobbyRoutingModule { }
