import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

const routes: Routes = [
  {
    path: 'org/:organization/game', loadChildren: () => import('./game.module').then(m => m.GameModule)
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)]
})
export class GameRoutingModule { }
