// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  envName: window['env']['envName'] || 'staging',
  production: !(window['env']['production'] === 'false'),
  hmr: window['env']['hmr'] === 'true',
  version: window['env']['version'] || 'unknown',

  services: {
    backendBaseUrl: window['env']['blindingApiBaseUrl'] || 'https://api.goblindtest.cloud',
    notificationsBaseUrl: window['env']['blindingNotificationsBaseUrl'] || 'wss://api.goblindtest.cloud/live',
    frogLabAuthBaseUrl: window['env']['frogLabAuthApiBaseUrl'] || 'https://auth.froglab.cloud',
    onboardingWebsiteBaseUrl: window['env']['onboardingWebsiteBaseUrl'] || 'https://board.froglab.cloud',
  },

  loggerConfig: {
    level: window['env']['loggerLevel'] || 'INFO',
    toast: window['env']['loggerToastEnabled'] === 'true'
  },

  useEmulators: false,
  firebase: {
    apiKey: window['env']['firebaseApiKey'] || 'AIzaSyC4njCdg8CZCp4Ernk4_KfMmS7UPgXKCD8',
    authDomain: window['env']['firebaseAuthDomain'] || 'blinding-io-staging.firebaseapp.com',
    databaseURL: window['env']['firebaseDatabaseUrl'] || 'https://blinding-io-staging.firebaseio.com',
    projectId: window['env']['firebaseProjectId'] || 'blinding-io-staging',
    storageBucket: window['env']['firebaseStorageBucket'] || 'blinding-io-staging.appspot.com',
    messagingSenderId: window['env']['firebaseMessagingSenderId'] || '1012976060463',
    appId: window['env']['firebaseAppId'] || '1:1012976060463:web:ef217928582d10bafadeda'
  },
};
