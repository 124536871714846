import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {connectAuthEmulator, getAuth, provideAuth} from '@angular/fire/auth';
import {connectDatabaseEmulator, getDatabase, provideDatabase} from '@angular/fire/database';

import {environment} from '../../environments/environment';
import {AppComponent} from './app.component';
import {CoreModule} from './core/core.module';
import {AppRoutingModule} from './app-routing.module';
import {LobbyRoutingModule} from './modules/lobby/lobby-routing.module';
import {GameRoutingModule} from './modules/game/game-routing.module';
import {HomeModule} from './modules/home/home.module';
import {ErrorStateMatcher, ShowOnDirtyErrorStateMatcher} from "@angular/material/core";
import {initializeApp, provideFirebaseApp} from "@angular/fire/app";
import {connectStorageEmulator, getStorage, provideStorage} from "@angular/fire/storage";
import {FrogCoreModule, FrogEventAuthModule} from '@frogconnexion/core-common/angular';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    CoreModule,
    BrowserAnimationsModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => {
      const auth = getAuth();
      if (environment.useEmulators) {
        connectAuthEmulator(auth, 'http://localhost:9099', {disableWarnings: true});
      }
      return auth;
    }),
    provideDatabase(() => {
      const database = getDatabase();
      if (environment.useEmulators) {
        connectDatabaseEmulator(database, 'localhost', 9000);
      }
      return database;
    }),
    provideStorage(() => {
      const storage = getStorage();
      if (environment.useEmulators) {
        connectStorageEmulator(storage, 'localhost', 9199);
      }
      return storage;
    }),
    FrogCoreModule.forRoot({
      log: environment.loggerConfig,
    }),
    FrogEventAuthModule.forRoot({
      apiBaseUrl: environment.services.frogLabAuthBaseUrl,
      onboardingWebsiteBaseUrl: environment.services.onboardingWebsiteBaseUrl,
      client: 'blinding-public',
      activity: 'blinding',
      debug: {
        disableRedirect: true
      }

    }),
    // Modules
    HomeModule,
    // Routing
    LobbyRoutingModule,
    GameRoutingModule,
    AppRoutingModule
  ],
  providers: [
    {provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
