import {Component, OnInit} from '@angular/core';

import {Router} from '@angular/router';
import {LoggerService} from '../../core/services/logger/logger.service';
import {FrogEventAuthService} from '@frogconnexion/core-common/angular';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  constructor(
    private authService: FrogEventAuthService,
    private logger: LoggerService,
    private router: Router,
  ) {
  }

  async ngOnInit() {
    if (!this.authService.latestUser) {
      this.authService.redirectToOnboarding();
      return;
    }
    try {
      await this.authService.verifyToken(this.authService.latestUser.accessToken);
      const u = this.authService.latestUser;
      await this.router.navigate([`/org/${u.organization}/lobby/players/${u.userId}`]);
    } catch (err) {
      this.logger.error(`Token is not valid`);
      this.authService.clear();
      this.authService.redirectToOnboarding();
      throw err;
    }
  }
}
